/* ./src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&family=Nunito:wght@400;500&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
   font-family:  'Montserrat', sans-serif;
} 

p,a, button{
    font-family: 'Nunito', sans-serif;
}

/* showcase background image */
.showcaseBagImg{
    background-image: url("../images/home2_optimized.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: cover center;
    background-attachment: fixed; */
}
/* 
featured dirty angels grid on landing page */
.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  
}

@media (max-width:1326px){
    .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1rem;
        justify-content: center;
        align-items: center;

    }
}

@media (max-width:758px){
    .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:448px){
    .grid-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
}

/* hoes picture size */
.picture-size{
    width: 261px;
    height: 389px;
}

@media (max-width:698px){
    .picture-size {
        width: 250px;
        height: 330px;
       
    }
}

/* styles for floating input */

  




/* 
grid for locations*/
.grid-locations {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% ;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  
}

.grid-locations ul{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    width: 100%;
}

.grid-locations li{
    margin-bottom: 1rem;
}


@media (max-width:758px){
    .grid-locations {
        display: grid;
        grid-template-columns: 25% 25% 25%;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:658px){
    .grid-locations {
        display: grid;
        grid-template-columns:  45% 45%;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:398px){
    .grid-locations {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
}

/* 
grid for locations*/
.grid-modelinfo {
    display: grid;
    grid-template-columns: 47% 47%;
    gap: 1rem;

}

@media (max-width:758px){
    .grid-modelinfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}


/* user dashboard */
.bacground-linearGradient{
    background: rgb(4,4,4);
    background: linear-gradient(90deg, rgba(4,4,4,1) 100%, rgba(34,32,24,1) 94%);
}

.bacground-linearGradient2{
    background: rgb(34,32,24);
    background: linear-gradient(90deg, rgba(34,32,24,1) 7%, rgba(4,4,4,0.8897849462365591) 67%);
}

.bacground-linearGradient3{
    background: rgb(4,4,4);
    background: linear-gradient(90deg, rgba(4,4,4,0.8897849462365591) 20%, rgba(34,32,24,1) 78%);
    
}

.ContactUsBoxShadow{
    box-shadow: -1px -1px 6px 3px  rgba(28, 26, 21, 0.7);
    -webkit-box-shadow: -1px -1px 6px 3px rgba(28, 26, 21, 0.7);
    -moz-box-shadow: -1px -1px 6px 3px rgba(28, 26, 21, 0.7);
}

.BalanceBoxshadow{
    box-shadow: -1px -1px 1px 3px rgba(255,255,255,0.03);
    -webkit-box-shadow: -1px -1px 1px 3px rgba(255,255,255,0.03);
    -moz-box-shadow: -1px -1px 1px 3px rgba(255,255,255,0.03);
}



